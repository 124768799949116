import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import {addRoutes} from "@/router/index"
import 'core-js';
import "babel-polyfill";



// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/reset.css';
Vue.use(ElementUI);

// 引入axios
import axios from 'axios';
Vue.prototype.$axios = axios;

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入vue-json-excel
// import JsonExcel from 'vue-json-excel'
// Vue.component('downloadExcel', JsonExcel)

// 引入时间插件
// import * as moment from 'moment'
// Vue.prototype.$moment = moment

//引入全局变量
import CommonParam from './utils/const'
Vue.prototype.$CommonParam = CommonParam;

Vue.config.productionTip = false

//icon组件
import eIconPicker from 'e-icon-picker';
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import 'e-icon-picker/lib/index.css'; // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库

//全局删除增加图标
Vue.use(eIconPicker, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true,//自带的图标，来自阿里
  eIconSymbol: true,//是否开启彩色图标
  addIconList: [],
  removeIconList: [],
  zIndex: 3100//选择器弹层的最低层,全局配置
});

new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    if(Boolean(localStorage.getItem("UserMenu"))){
      addRoutes(JSON.parse(localStorage.getItem("UserMenu")))
    }
  }
}).$mount('#app')
