export default{
    state:{
        isCollapse:false,//导航栏是否折叠
    },
    mutations:{
        // 修改导航栏展开和收起的方法
        CollapseMenu(state){
            state.isCollapse=!state.isCollapse
        }
    }
}