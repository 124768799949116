/*
* 公共静态变量
 */

//接口返回状态码
const SUCCESS = "200";  //成功
const TIMEOUT = "400";  //认证失败

//返显图片
const FILEURL = "/file/";

//文件下载
const FILEDOWNLOADURL = "/arranged/";

//文件导出 - 不同运行环境需要更改
//const FILEEXPORTURL = "http://119.163.194.77:38101/"; //银方测试服务器
//const FILEEXPORTURL = "http://111.15.224.109:8101/"  //生产-自建服务器
const FILEEXPORTURL = "https://bh.rzcg.com.cn/dzbh/"    //生产-阿里云


export default {
    SUCCESS,
    TIMEOUT,
    FILEURL,
    FILEDOWNLOADURL,
    FILEEXPORTURL
}


