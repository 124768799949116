import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login/login.vue"),
    },
    {
        path: "/",
        name: "layout",
        // redirect: "/login",
        // redirect:"/index",
        component: () => import("../layout"),
        children: [
            {
                path: '/welcome',
                name: 'welcome',
                component: () => import('../views/welcome.vue'),
                meta: {
                    title: "首页",
                    name: ""
                }
            },
        ]
    },
    {
        path: "/prov",
        name: "prov",
        // redirect: "/login",
        // redirect:"/index",
        component: () => import("../views/prov/prov.vue"),
    },
    {
        path: "/sdgk",
        name: "sdgk",
        // redirect: "/login",
        // redirect:"/index",
        component: () => import("../views/sdgk/sdgk.vue"),
    }
  ]
const router = new VueRouter({
  // 页面刷新白屏问题
  mode: "history",
  routes,
});

//路由拦截
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.path === "/login") {
    next();
  } else {
    if (Boolean(localStorage.getItem("token"))) {
      // 通过vuex state获取当前的user是否存在
      if (to.path == "/") {
        next("/welcome");
      } else {
        next();
      }
    }else if(to.path === '/prov'){
        next()
    }
    else if(to.path === '/sdgk'){
        next()
    }
    else {
      next("/login");
    }
  }
});
router.afterEach(() => {
  NProgress.done();
});

//添加路由的方法
export function addRoutes(res) {
  res.forEach((Router, index) => {
    if (Router.router == "0") {
      router.addRoute({
        path: Router.path,
        name: Router.title,
        component: () => import("@/views/" + Router.component + ".vue"),
      });
    } else {
      router.addRoute("layout", {
        path: Router.path,
        name: Router.title,
        component: () => import("@/views/" + Router.component + ".vue"),
      });
    }
    let childrens = Router.children;
    if (childrens != undefined) {
      for (let i = 0; i < childrens.length; i++) {
        if (childrens[i] != undefined) {
          router.addRoute("layout", {
            path: childrens[i].path,
            name: childrens[i].title,
            meta: {
              parentName: childrens[i].parentName,
            },
            component: () =>
              import("@/views/" + childrens[i].component + ".vue"),
          });
        }
      }
    }
  });
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router;
